<template>
  <div class="main">
    <div class="search">
      <el-select v-model="log.byField" size="mini"
                 placeholder="검색조건"
                 style="width:120px;margin-left: 10px">
        <el-option label="전체" :value="null">전체</el-option>
        <el-option label="회원닉네임" :value="managerConst.LogLogin.SEARCH_BYFEILD_NICKNAME">회원닉네임
        </el-option>
        <el-option label="IP" :value="managerConst.LogLogin.SEARCH_BYFEILD_IP">IP
        </el-option>
      </el-select>
      <el-input type="text" size="mini" v-model="log.searchString" placeholder="아이디/IP"
                style="width: 150px;margin-left: 5px"></el-input>
      <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
      <el-button type="primary" @click="getLogList" size="mini" style="margin-left: 10px">검색</el-button>
      <el-select v-model="log.loginType" @change="getLogList" size="mini" placeholder="로그타입"
                 style="width:110px;margin-left: 5px">
        <el-option label="전체" :value="null">전체</el-option>
        <el-option label="회원로그" :value="managerConst.LogLogin.LOG_LOGIN_TYPE_USER">회원정보</el-option>
        <el-option label="파트너로그" :value="managerConst.LogLogin.LOG_LOGIN_TYPE_PARTNER">파트너로그</el-option>
        <el-option label="관리자로그" :value="managerConst.LogLogin.LOG_LOGIN_TYPE_MANAGER">관리자로그</el-option>
      </el-select>
    </div>

    <div class="data">
      <div style="width: 100%;margin: 5px 0">
        도메인접속회수 (최근한달)
        <span v-for="item in domainList" class="badge badge-warning" style="font-size: 14px">
                                    {{ item.domain }}({{ item.c }})
                                </span>
      </div>
      <el-table
          :data="logList"
          style="width: 100%"
          max-height="730"
          border>
        <el-table-column
            label="날짜"
            width="130">
          <template slot-scope="scope">
            {{ scope.row.createTime|datef('MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>

        <el-table-column
            label="IP"
            width="300">
          <template slot-scope="scope">
            {{ scope.row.loginIp }}
          </template>
        </el-table-column>
        <el-table-column
            label="국가"
            width="100">
          <template slot-scope="scope">
            <img src="../../assets/images/ipcountry/KR.gif" alt="" v-if="scope.row.ipcountry == 'KR'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/CN.gif" alt="" v-if="scope.row.ipcountry == 'CN'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/CN.gif" alt="" v-if="scope.row.ipcountry == 'HK'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/JP.gif" alt="" v-if="scope.row.ipcountry == 'JP'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/KH.gif" alt="" v-if="scope.row.ipcountry == 'KH'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/PH.gif" alt="" v-if="scope.row.ipcountry == 'PH'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/SG.gif" alt="" v-if="scope.row.ipcountry == 'SG'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/TH.gif" alt="" v-if="scope.row.ipcountry == 'TH'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/US.gif" alt="" v-if="scope.row.ipcountry == 'US'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/VN.gif" alt="" v-if="scope.row.ipcountry == 'VN'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            {{ scope.row.ipcountry }}
          </template>
        </el-table-column>
        <el-table-column
            label="지역"
            width="100">
          <template slot-scope="scope">
            {{ scope.row.ipregion }}
          </template>
        </el-table-column>
        <el-table-column
            label="VPN타입"
            width="200">
          <template slot-scope="scope">
            {{ scope.row.networktype }}
          </template>
        </el-table-column>



        <el-table-column
            label="DESC"
            width="300">
          <template slot-scope="scope">
            <div v-if="scope.row.loginType == managerConst.LogLogin.LOG_LOGIN_TYPE_USER"
                 style="cursor: pointer"
                 @click="editUser(scope.row.loginId,managerConst.MODE_EDIT)">
              {{ scope.row.description }}
            </div>
            <div v-if="scope.row.loginType == managerConst.LogLogin.LOG_LOGIN_TYPE_PARTNER"
                 style="cursor: pointer"
                 @click="editPartner(scope.row.loginId,managerConst.MODE_EDIT)">
              {{ scope.row.description }}
            </div>
            <div v-if="scope.row.loginType == managerConst.LogLogin.LOG_LOGIN_TYPE_MANAGER">
              {{ scope.row.description }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="Browser"
            width="700">
          <template slot-scope="scope">
            {{ scope.row.browser }}
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div class="pagePanel">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[25, 50, 100, 150, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal">
      </el-pagination>
    </div>

  </div>

</template>

<script>

import OnlineUserComp from "../../components/administrator/OnlineUserComp";
import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
import {updateGroup} from "../../network/manager/groupRequest";
import {manager} from "../../common/administrator/managerMixin";
import managerConst from "../../common/administrator/managerConst";
import {getLogCashPointList} from "../../network/manager/logCashPointRequest";
import {getModifiedHistoryList, updateAllModifiedHistory} from "../../network/manager/logModifyRequest";
import Vue from "vue";
import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
import {getLoginLogList} from "../../network/manager/logLoginRequest";
import DateSelectorComp from "../../components/administrator/DateSelectorComp";
import {Loading} from "element-ui";
import AgentEditToast from "../../components/administrator/UserPanel/AgentEditToast";

Vue.prototype.$userEditToast = UserEditToast
Vue.prototype.$partnerEditToast = PartnerEditToast
Vue.prototype.$agentEditToast = AgentEditToast
export default {
  name: "ManagerLoginHistory",
  mixins: [manager],
  components: {DateSelectorComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
  data() {
    return {
      startDate: '',
      endDate: '',
      pageNum: 1,
      pageSize: 25,
      pageTotal: 0,
      log: {},
      logList: [],
      domainList: []

    }
  },
  methods: {
    editPartner(id, mode) {
      this.$agentEditToast({'agentId': id, 'mode': mode})
    },
    editUser(userId, mode) {
      this.$userEditToast({'userId': userId, 'mode': mode})
    },
    getLogList() {
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      this.log.startDate = this.startDate;
      this.log.endDate = this.endDate;
      getLoginLogList(this.log, this.pageNum, this.pageSize).then(res => {
        this.logList = res.data.data.result
        this.domainList = res.data.data.domainMap
        this.pageTotal = res.data.total
        loadingInstance.close()
      })
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getLogList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getLogList();
    },
    setStartDate() {
      this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
    },
    setEndDate() {
      this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
    },
  },
  created() {
    this.log.loginType = managerConst.LogLogin.LOG_LOGIN_TYPE_USER;
    this.startDate = this.$moment().subtract(30, 'days').format('yyyy-MM-DD')
    this.endDate = this.$moment().format('yyyy-MM-DD')
    this.getLogList()
  },
}
</script>

<style scoped>

</style>